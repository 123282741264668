/**
 * External dependencies.
 */
import ApolloClient from 'apollo-boost';
import fetch from 'isomorphic-fetch';

/**
 * Internal dependencies.
 */
import { siteURL } from '../../client-config';

/**
 * Apollo client configuration.
 */
export const client = new ApolloClient( {
	uri: `${ siteURL }/graphql`,
	fetch
} );
