/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

/**
 * Internal dependencies.
 */
import './src/fonts/fonts.css';

/**
 * On client entry.
 *
 * @return {void}
 */
export const onClientEntry = () => {
	window.onload = () => document.documentElement.classList.add( 'app-loaded' );

	/**
	 * Polyfill jQuery for hubspot form and provide only what it needs since its script uses jQuery on form submit.
	 * Hoping that there would a truly React component available for hubspot in future and we can remove the below code.
	 */
	window.jQuery = window.jQuery || function( selector ) {
		const form = 'string' === selector ? document.querySelector( selector ) : selector;

		return {
			serializeArray: function() {
				var arr = [];

				Array.prototype.slice.call( form.elements ).forEach( function( field ) {
					if ( ! field.name || field.disabled || [ 'file', 'reset', 'submit', 'button' ].indexOf( field.type ) > -1 ) return;
					if ( field.type === 'select-multiple' ) {
						Array.prototype.slice.call( field.options ).forEach( function( option ) {
							if ( ! option.selected ) return;
							arr.push( {
								name: field.name,
								value: option.value
							} );
						} );

						return;
					}

					if ( [ 'checkbox', 'radio' ].indexOf( field.type ) > -1 && ! field.checked ) return;

					arr.push( {
						name: field.name,
						value: field.value
					} );
				} );

				return arr;
			}
		};
	};
}

export { wrapRootElement } from './src/apollo/wrap-root-element';
